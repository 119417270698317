import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { keyframes } from '@mui/system';
import { Link } from "react-router-dom";

// Keyframes for gradient animation
const gradientAnimation = keyframes`
    0% { background-position: 0 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0 50%; }
`;

// Styles moved to objects for reusability and separation
const bannerStyles = {
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    color: '#E6E6E6',
    background: 'linear-gradient(120deg, #182759, #075DD1, #182759)',
    backgroundSize: '300% 300%',
    animation: `${gradientAnimation} 15s ease infinite`,
};

const titleStyles = {
    fontWeight: 'bold',
    fontFamily: 'Playwright New Zealand',
    fontSize: { xs: '1.8rem', sm: '2.4rem', md: '3rem' },
    mb: 2,
    textShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
};

const subtitleStyles = {
    fontFamily: 'Playwright New Zealand',
    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.75rem' },
    maxWidth: { xs: '90%', md: '800px' },
    mb: 4,
    opacity: 0.9,
};

const buttonStyles = {
    padding: { xs: '0.6rem 1.5rem', md: '0.8rem 2rem' },
    fontSize: { xs: '1rem', md: '1.2rem' },
    fontFamily: 'Playwright New Zealand',
    backgroundColor: '#030519',
    '&:hover': { backgroundColor: '#075DD1' },
    borderRadius: '50px',
    textTransform: 'none',
    boxShadow: '0px 10px 20px rgba(0, 128, 255, 0.3)',
};

// Hero Banner Component
const Banner = ({ title, subtitle, buttonText }) => {

    return (
        <Box sx={bannerStyles}>
            <Typography variant="h2" sx={titleStyles}>
                {title}
            </Typography>

            <Typography variant="h5" sx={subtitleStyles}>
                {subtitle}
            </Typography>

            <Button
                variant="contained"
                color="primary"
                sx={buttonStyles}
                component={Link}
                to="/contact"
            >
                {buttonText}
                <ArrowDownwardIcon sx={{ ml: 1 }} />
            </Button>
        </Box>
    );
};

export default Banner;
