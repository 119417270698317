// Team.js
import React from 'react';
import Banner from '../components/Banner';
import {motion} from "framer-motion";
import {Box, Button, Container, Grid, Paper, Typography} from "@mui/material";
import { FaBullhorn, FaLaptopCode, FaHandshake, FaUserGraduate, FaCheckCircle, FaFileAlt, FaEnvelopeOpenText } from 'react-icons/fa';

// Departments data
const departments = [
    {
        title: 'Marketing',
        icon: <FaBullhorn />,
        description: 'Help us grow our brand and connect with our audience. Open for both project-based and internship roles.',
    },
    {
        title: 'Sales',
        icon: <FaHandshake />,
        description: 'Join our team as a permanent sales representative with commission-based earnings. Ideal for motivated self-starters.',
    },
    {
        title: 'Development',
        icon: <FaLaptopCode />,
        description: 'We are always seeking skilled developers for project-based freelance roles, with opportunities in web, mobile, and software development.',
    },
    {
        title: 'Internships',
        icon: <FaUserGraduate />,
        description: 'Gain hands-on experience across various departments. Open to all enthusiastic learners.',
    },
];

// Hiring process data
const hiringProcess = [
    {
        title: 'Step 1: Apply',
        icon: <FaFileAlt />,
        description: 'Submit your application through the "Apply Now" button on the job listing.',
    },
    {
        title: 'Step 2: Interview',
        icon: <FaEnvelopeOpenText />,
        description: 'Our HR team will schedule a one-on-one interview with you to discuss your experience.',
    },
    {
        title: 'Step 3: Offer',
        icon: <FaCheckCircle />,
        description: 'If you’re the right fit, you’ll receive a formal offer to join our team!',
    },
];

// Animation Variants
const fadeUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, type: 'spring', stiffness: 100 } }
};

const hoverEffect = {
    hover: { scale: 1.1, transition: { duration: 0.3 } }
};

const Career = () => {
    return (
        <>
            <Banner
                title={'Your Career Starts Here'}
                subtitle={'Join a team that values innovation, collaboration, and growth. Be part of something bigger'}
                buttonText={'Send Resume'}
            />

            <Box sx={{ px: { xs: 4, md: 10 }, py: 6, backgroundColor: '#E6E6E6' }}>
                {/* Current Job Openings */}
                <motion.div variants={fadeUp} initial="hidden" animate="visible">
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#030519', mb: 3, textAlign: 'center' }}>
                        Current Job Openings
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {departments.map((dept, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <motion.div variants={fadeUp} initial="hidden" animate="visible" whileHover="hover">
                                    <Paper
                                        sx={{
                                            p: 4,
                                            textAlign: 'center',
                                            minHeight: 240,
                                            boxShadow: 4,
                                            borderRadius: 2,
                                            '&:hover': { boxShadow: 6 },
                                            transition: 'box-shadow 0.3s ease-in-out',
                                            backgroundColor: '#FFFFFF',
                                        }}
                                        component={motion.div}
                                        variants={hoverEffect}
                                    >
                                        <Box sx={{ fontSize: 50, color: '#075DD1', mb: 2 }}>
                                            {dept.icon}
                                        </Box>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mb: 1 }}>
                                            {dept.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#182759', fontSize: '0.9rem', lineHeight: 1.6 }}>
                                            {dept.description}
                                        </Typography>
                                    </Paper>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>
                </motion.div>

                {/* Our Hiring Process */}
                <motion.div variants={fadeUp} initial="hidden" animate="visible">
                    <Container sx={{ mt: 8 }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#030519', mb: 3, textAlign: 'center' }}>
                            Our Hiring Process
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            {hiringProcess.map((step, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <motion.div variants={fadeUp} initial="hidden" animate="visible" whileHover="hover">
                                        <Paper
                                            sx={{
                                                p: 4,
                                                textAlign: 'center',
                                                minHeight: 240,
                                                boxShadow: 4,
                                                borderRadius: 2,
                                                '&:hover': { boxShadow: 6 },
                                                transition: 'box-shadow 0.3s ease-in-out',
                                                backgroundColor: '#FFFFFF',
                                            }}
                                            component={motion.div}
                                            variants={hoverEffect}
                                        >
                                            <Box sx={{ fontSize: 50, color: '#075DD1', mb: 2 }}>
                                                {step.icon}
                                            </Box>
                                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mb: 1 }}>
                                                {step.title}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#182759', fontSize: '0.9rem', lineHeight: 1.6 }}>
                                                {step.description}
                                            </Typography>
                                        </Paper>
                                    </motion.div>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </motion.div>

                {/* Call-to-Action */}
                <Box sx={{ textAlign: 'center', mt: 6 }}>
                    <Button variant="contained" color="primary" href="mailto:careers@atomic.com" sx={{ py: 1.5, px: 4, fontSize: '1.1rem', '&:hover': { transform: 'scale(1.05)' } }}>
                        Apply Now
                    </Button>
                </Box>
            </Box>

        </>
    );
};

export default Career;
