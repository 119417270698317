// TechStack.js
import React, {useState} from 'react';
import Banner from '../components/Banner';
import {tools} from "../components/carouselMini/ToolsCarousel";
import {Box, Button, Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import Slider from "react-slick";
import { SiActix, SiAdobe, SiAndroid, SiAndroidstudio, SiAngular, SiApache, SiApachespark, SiAzuredevops, SiBitbucket,
         SiBlazor, SiCanva, SiCircleci, SiCodeigniter, SiCsharp, SiCypress, SiDart, SiDatadog, SiDbt, SiDigitalocean,
         SiDjango, SiDocker, SiElasticsearch, SiExpress, SiFastapi, SiFigma, SiFirebase, SiFlask, SiFlutter, SiGin,
         SiGithub, SiGithubactions, SiGitlab, SiGrafana, SiHeroku, SiHibernate, SiIntellijidea, SiInvision, SiJenkins,
         SiJest, SiJira, SiJupyter, SiKotlin, SiKtor, SiKubernetes, SiLaravel, SiMongodb, SiMysql, SiNestjs, SiNetlify,
         SiNewrelic, SiNextdotjs, SiPandas, SiPostgresql, SiPostman, SiPresto, SiPrisma, SiPrometheus, SiPycharm,
         SiPytest, SiPytorch, SiQuarkus, SiReact, SiRedis, SiRemix, SiReplit, SiRocket, SiSelenium, SiSentry, SiSketch,
         SiSlack, SiSplunk, SiSpringboot, SiSqlalchemy, SiSvelte, SiSwift, SiSymfony, SiTableau, SiTeamcity,
         SiTensorflow, SiTide, SiTravisci, SiTrello, SiTypescript, SiUnity, SiVapor, SiVercel, SiVisualstudiocode,
         SiVuedotjs, SiXamarin, SiXcode} from "react-icons/si";
import {FaAws, FaGithub, FaJava, FaJs, FaPhp, FaPython, FaRust, FaSwift} from "react-icons/fa";
import {FaGolang} from "react-icons/fa6";

export const languageFrameworks = [
    // Python
    {
        name: 'Python', icon: <FaPython />, description: 'Popular for web, data science, ML, and automating tasks in development.',
        frameworks: [
            { name: 'Django', icon: <SiDjango />, description: 'A high-level Python framework for fast, secure web app development.' },
            { name: 'Flask', icon: <SiFlask />, description: 'A lightweight, flexible web framework in Python for rapid development.' },
            { name: 'FastAPI', icon: <SiFastapi />, description: 'A high-performance Python framework for modern, fast API development.' },
            { name: 'TensorFlow', icon: <SiTensorflow />, description: 'An open-source ML and AI framework, widely used for deep learning.' },
            { name: 'PyTorch', icon: <SiPytorch />, description: 'A flexible, open-source library for machine learning and deep learning.' },
            { name: 'Pandas', icon: <SiPandas />, description: 'A Python library for data analysis with high-performance data structures.' }
        ]
    },
    // JavaScript
    {
        name: 'JavaScript', icon: <FaJs />, description: 'Vital for web front-end (React) and back-end (Node.js), widely adopted in tech.',
        frameworks: [
            { name: 'React', icon: <SiReact />, description: 'A library for building UI components, mainly for single-page web apps.' },
            { name: 'Vue.js', icon: <SiVuedotjs />, description: 'A progressive framework for building UIs, known for simplicity.' },
            { name: 'Angular', icon: <SiAngular />, description: 'A TypeScript-based framework for building dynamic single-page apps.' },
            { name: 'Next.js', icon: <SiNextdotjs />, description: 'A React framework for building fast, optimized web applications.' },
            { name: 'Express.js', icon: <SiExpress />, description: 'A web framework for Node.js, widely used to build APIs and apps.' },
            { name: 'Svelte', icon: <SiSvelte />, description: 'A UI framework that compiles components to highly efficient JavaScript.' }
        ]
    },
    // TypeScript
    {
        name: 'TypeScript', icon: <SiTypescript />, description: 'JavaScript superset with static typing, favored in large-scale application dev.',
        frameworks: [
            { name: 'NestJS', icon: <SiNestjs />, description: 'A progressive framework for scalable and efficient server-side apps.' },
            { name: 'Next.js (TypeScript)', icon: <SiNextdotjs />, description: 'A React framework with TypeScript support for quick, smooth apps.' },
            { name: 'Angular (TypeScript)', icon: <SiAngular />, description: 'A TypeScript-based framework for scalable, maintainable web apps.' },
            { name: 'Remix', icon: <SiRemix />, description: 'A fast framework for creating modern web applications with React.' }
        ]
    },
    // Java
    {
        name: 'Java', icon: <FaJava />, description: 'Dominates enterprise apps, backend, and Android; stable and widely implemented.',
        frameworks: [
            { name: 'Spring Boot', icon: <SiSpringboot />, description: 'A framework for building Java apps with embedded servers, no XML.' },
            { name: 'Hibernate', icon: <SiHibernate />, description: 'A tool for mapping Java objects to relational databases with ease.' },
            { name: 'Apache Struts', icon: <SiApache />, description: 'A Java framework for building web apps using the MVC architecture.' },
            { name: 'Quarkus', icon: <SiQuarkus />, description: 'A Java framework optimized for cloud-native apps, using GraalVM.' }
        ]
    },
    // Kotlin
    {
        name: 'Kotlin', icon: <SiKotlin />, description: 'Android’s modern choice over Java; concise, safer, and easy-to-read language.',
        frameworks: [
            { name: 'Ktor', icon: <SiKtor />, description: 'A flexible, asynchronous Kotlin framework for building web apps.' },
            { name: 'Spring Boot (Kotlin)', icon: <SiSpringboot />, description: 'A Kotlin-friendly Spring Boot for easy backend app development.' },
            { name: 'Android Architecture Components', icon: <SiAndroid />, description: 'Libraries to help design maintainable, robust Android apps.' }
        ]
    },
    // Swift
    {
        name: 'Swift', icon: <FaSwift />, description: 'Built for iOS/macOS development, Swift powers Apple apps with high performance.',
        frameworks: [
            { name: 'SwiftUI', icon: <SiSwift />, description: 'A declarative framework for building native UIs across Apple devices.' },
            { name: 'Vapor', icon: <SiVapor />, description: 'A server-side Swift framework for building fast, secure web apps.' }
        ]
    },
    // Rust
    {
        name: 'Rust', icon: <FaRust />, description: 'Performance-focused with memory safety, used in system programming and beyond.',
        frameworks: [
            { name: 'Rocket', icon: <SiRocket />, description: 'A Rust web framework focused on safety, speed, and simplicity.' },
            { name: 'Actix', icon: <SiActix />, description: 'A Rust framework for fast, scalable, actor-based web apps.' },
            { name: 'Tide', icon: <SiTide />, description: 'A minimal Rust framework designed for async, rapid web development.' }
        ]
    },
    // PHP
    {
        name: 'PHP', icon: <FaPhp />, description: 'Important for CMSs like WordPress, PHP remains a web development staple.',
        frameworks: [
            { name: 'Laravel', icon: <SiLaravel />, description: 'A PHP framework with elegant syntax for modern, scalable web apps.' },
            { name: 'Symfony', icon: <SiSymfony />, description: 'A flexible PHP framework for building robust, large web applications.' },
            { name: 'CodeIgniter', icon: <SiCodeigniter />, description: 'A PHP framework for lightweight, rapid application development.' }
        ]
    },
    // SQL
    {
        name: 'SQL', icon: <SiMysql />, description: 'Database language key for data management and analytics, not general-purpose.',
        frameworks: [
            { name: 'Apache Spark SQL', icon: <SiApache />, description: 'A framework for distributed data processing, supporting SQL-based analysis.' },
            { name: 'Presto', icon: <SiPresto />, description: 'A distributed SQL engine for high-speed queries on large datasets.' },
            { name: 'DBT', icon: <SiDbt />, description: 'A transformation framework for analysts, turning raw data into insights.' }
        ]
    },
    // C#
    {
        name: 'C#', icon: <SiCsharp />, description: 'Popular in enterprise, Windows, and game dev with Unity, a versatile language.',
        frameworks: [
            { name: 'Unity', icon: <SiUnity />, description: 'A cross-platform engine for building 2D/3D games and simulations.' },
            { name: 'Blazor', icon: <SiBlazor />, description: 'A C# framework for building web applications without JavaScript.' },
            { name: 'Xamarin', icon: <SiXamarin />, description: 'A framework for building cross-platform mobile apps using C#.' }
        ]
    },
    // Go
    {
        name: 'Go', icon: <FaGolang />, description: 'Google’s language for backend, microservices, and cloud with a simple syntax.',
        frameworks: [
            { name: 'Gin', icon: <SiGin />, description: 'A minimal, efficient Go framework for fast, scalable web applications.' }
        ]
    },
    // Dart
    {
        name: 'Dart', icon: <SiDart />, description: 'Used with Flutter, Google’s framework for cross-platform mobile applications.',
        frameworks: [
            { name: 'Flutter', icon: <SiFlutter />, description: 'A toolkit for building native apps across mobile, web, and desktop.' }
        ]
    },
];

const toolsDomain = [
    {
        name: 'Designing',
        icon: <SiFigma />,
        description: 'Tools for designing and prototyping user interfaces and user experiences.',
        tools: [
            { name: "Figma", icons: <SiFigma />, description: "Design and prototype tool for collaborative UI/UX workflows in cloud." },
            { name: "Adobe XD", icons: <SiAdobe />, description: "A design tool for creating web and mobile prototypes with Adobe integration." },
            { name: "Sketch", icons: <SiSketch />, description: "Digital design software for web and mobile interfaces with plugins and ease." },
            { name: "InVision", icons: <SiInvision />, description: "Product design platform enabling collaborative workflows for UI/UX teams." },
            { name: "Canva", icons: <SiCanva />, description: "Web-based tool with templates for creating social media and graphics easily." }
        ]
    },
    {
        name: 'Development',
        icon: <SiVisualstudiocode />,
        description: 'Tools for writing, testing, and debugging code in various programming languages.',
        tools: [
            { name: "Visual Studio Code", icons: <SiVisualstudiocode />, description: "Powerful editor with debugging, Git, and multiple language support extensions." },
            { name: "IntelliJ IDEA", icons: <SiIntellijidea />, description: "Java IDE for JVM languages with robust debugging and code completion." },
            { name: "PyCharm", icons: <SiPycharm />, description: "IDE for Python with code assistance, debugging, and Django support." },
            { name: "Android Studio", icons: <SiAndroidstudio />, description: "IDE for Android app development with tools for coding, testing, and debugging." },
            { name: "Xcode", icons: <SiXcode />, description: "IDE for macOS/iOS with powerful debugging and testing for app development." },
            { name: "Repl.it", icons: <SiReplit />, description: "Online platform to write, run, and share code in various languages in-browser." },
            { name: "GitHub Codespaces", icons: <SiGithub />, description: "Browser-based dev environment in GitHub for writing, testing, and debugging." }
        ]
    },
    {
        name: 'Version Control',
        icon: <FaGithub />,
        description: 'Tools for version control, code management, and team collaboration.',
        tools: [
            { name: "GitHub", icons: <FaGithub />, description: "Platform for Git version control, code management, and team collaboration." },
            { name: "GitLab", icons: <SiGitlab />, description: "Git repository and DevOps platform with CI/CD and project tracking tools." },
            { name: "Bitbucket", icons: <SiBitbucket />, description: "Git version control platform that integrates with Jira for project workflows." },
            { name: "JIRA", icons: <SiJira />, description: "Agile project management tool with issue tracking, sprints, and workflow." },
            { name: "Trello", icons: <SiTrello />, description: "Visual project management tool with boards, lists, and cards for tasks." },
            { name: "Slack", icons: <SiSlack />, description: "Team messaging platform with channels, DMs, and tool integrations." }
        ]
    },
    {
        name: 'Deployment',
        icon: <SiDocker />,
        description: 'Tools for deploying, managing, and scaling applications using containers.',
        tools: [
            { name: "Docker", icons: <SiDocker />, description: "Tool for building, deploying, and managing applications in containers." },
            { name: "Kubernetes", icons: <SiKubernetes />, description: "Platform for automated deployment, scaling, and management of containers." },
            { name: "Heroku", icons: <SiHeroku />, description: "PaaS for building, running, and scaling apps with managed runtime services." },
            { name: "AWS Elastic Beanstalk", icons: <FaAws />, description: "Managed service for deploying, running, and scaling applications on AWS." },
            { name: "Netlify", icons: <SiNetlify />, description: "Platform for deploying static sites and serverless apps with Git integration." },
            { name: "Vercel", icons: <SiVercel />, description: "Platform for frontend developers to build and deploy sites and apps fast." },
            { name: "Firebase", icons: <SiFirebase />, description: "Platform offering database, hosting, authentication, and cloud functions." },
            { name: "DigitalOcean App Platform", icons: <SiDigitalocean />, description: "PaaS to build, deploy, and scale apps in multiple programming languages." }
        ]
    },
    {
        name: 'CI/CD',
        icon: <SiJenkins />,
        description: 'Continuous integration and continuous delivery tools for automating workflows.',
        tools: [
            { name: "GitHub Actions", icons: <SiGithubactions />, description: "CI/CD platform to automate workflows like tests and deployments on GitHub." },
            { name: "Jenkins", icons: <SiJenkins />, description: "Open-source automation server for CI/CD with extensive plugin support." },
            { name: "CircleCI", icons: <SiCircleci />, description: "CI/CD platform to automate code builds, tests, and deployment processes." },
            { name: "Travis CI", icons: <SiTravisci />, description: "Cloud CI/CD tool for automating builds and tests for GitHub repositories." },
            { name: "GitLab CI/CD", icons: <SiGitlab />, description: "CI/CD service in GitLab for automating tests, workflows, and deployments." },
            { name: "TeamCity", icons: <SiTeamcity />, description: "CI/CD server by JetBrains with support for various languages and projects." },
            { name: "Azure DevOps", icons: <SiAzuredevops />, description: "Microsoft DevOps suite for CI/CD, version control, and project tracking." }
        ]
    },
    {
        name: 'Testing & Debugging',
        icon: <SiSelenium />,
        description: 'Testing frameworks for ensuring application quality and performance.',
        tools: [
            { name: "Selenium", icons: <SiSelenium />, description: "Framework for web automation, enabling user simulations for app testing." },
            { name: "Jest", icons: <SiJest />, description: "JS testing framework for unit and integration tests with mocking support." },
            { name: "Cypress", icons: <SiCypress />, description: "JavaScript end-to-end testing framework with live reloading and debug." },
            { name: "PyTest", icons: <SiPytest />, description: "Python framework for scalable unit and integration testing automation." },
            { name: "Postman", icons: <SiPostman />, description: "API development tool for sending requests and inspecting HTTP responses." }
        ]
    },
    {
        name: 'Databases',
        icon: <SiPostgresql />,
        description: 'Relational and NoSQL databases for managing application data.',
        tools: [
            { name: "PostgreSQL", icons: <SiPostgresql />, description: "Reliable open-source SQL database for complex applications and extensibility." },
            { name: "MySQL", icons: <SiMysql />, description: "Open-source relational database for web apps with speed and flexibility." },
            { name: "MongoDB", icons: <SiMongodb />, description: "NoSQL database for scalable, JSON-like data storage in web applications." },
            { name: "Firebase Realtime Database", icons: <SiFirebase />, description: "Cloud-hosted NoSQL database syncing data in real-time for mobile/web." },
            { name: "Redis", icons: <SiRedis />, description: "In-memory key-value store used for caching, messaging, and real-time apps." },
            { name: "Prisma", icons: <SiPrisma />, description: "Toolkit for Node.js/TypeScript for easy database access and schema management." },
            { name: "SQLAlchemy", icons: <SiSqlalchemy />, description: "Python SQL toolkit and ORM for database interaction and complex queries." }
        ]
    },
    {
        name: 'Data Science & AI',
        icon: <SiTensorflow />,
        description: 'Tools for machine learning, data analysis, and AI model deployment.',
        tools: [
            { name: "Jupyter Notebook", icons: <SiJupyter />, description: "Interactive coding, visualizations, and narrative environment for data." },
            { name: "TensorFlow", icons: <SiTensorflow />, description: "Machine learning library for creating and deploying scalable ML models." },
            { name: "PyTorch", icons: <SiPytorch />, description: "Python ML framework for rapid prototyping and deploying AI models." },
            { name: "Apache Spark", icons: <SiApachespark />, description: "Unified analytics engine for big data processing, ML, and stream tasks." },
            { name: "Pandas", icons: <SiPandas />, description: "Data manipulation library for Python with DataFrames and analysis tools." },
            { name: "Tableau", icons: <SiTableau />, description: "Visualization tool for interactive dashboards and business intelligence." }
        ]
    },
    {
        name: 'Monitoring',
        icon: <SiPrometheus />,
        description: 'Monitoring tools for tracking performance and real-time system health.',
        tools: [
            { name: "Prometheus", icons: <SiPrometheus />, description: "Monitoring and time-series database system with powerful querying." },
            { name: "Grafana", icons: <SiGrafana />, description: "Analytics platform for visualizing time-series data with interactive dashboards." },
            { name: "ELK Stack", icons: <SiElasticsearch />, description: "Centralized logging platform with Elasticsearch, Logstash, and Kibana." },
            { name: "New Relic", icons: <SiNewrelic />, description: "Platform for monitoring app performance and system metrics in real-time." },
            { name: "Datadog", icons: <SiDatadog />, description: "Monitoring and analytics platform for infrastructure and application logs." },
            { name: "Sentry", icons: <SiSentry />, description: "Real-time error tracking tool to monitor and fix app crashes." },
            { name: "Splunk", icons: <SiSplunk />, description: "Data platform for monitoring, analyzing, and visualizing machine data." }
        ]
    }
];

const Tech = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [activeSection, setActiveSection] = useState('languageFrameworks');

    // Slider settings
    const SLIDER_SETTINGS = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    // Button styles
    const buttonStyles = {
        fontWeight: 'bold',
        width: '400px',
        margin: 4,
    };

    // Primary colors used
    const COLORS = {
        mainBlue: '#075DD1',
        textPrimary: '#030519',
        textSecondary: '#182759',
        backgroundGray: '#E6E6E6',
    };

    // Function to render a single category item
    const renderCategoryItem = (category, dataType) => (
        <Grid container spacing={2} key={category.name} sx={{ mb: 6 }} alignItems="center">
            <Grid item xs={12} md={3}>
                <Paper elevation={4} sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                    <Box sx={{ fontSize: '3rem', color: COLORS.mainBlue, mb: 2 }}>
                        {React.cloneElement(category.icon, { fontSize: '4rem' })}
                    </Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: COLORS.textPrimary }}>
                        {category.name}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: COLORS.textSecondary, fontStyle: 'italic' }}>
                        {category.description}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
                <Slider {...SLIDER_SETTINGS}>
                    {category[dataType].map((service) => (
                        <Box key={service.name}>
                            <Paper
                                elevation={3}
                                sx={{
                                    p: 4,
                                    textAlign: 'center',
                                    minHeight: '130px',
                                    margin: 1,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                                }}
                            >
                                <Box sx={{ fontSize: '3rem', color: COLORS.mainBlue, mb: 2 }}>
                                    {React.cloneElement(service.icon || service.icons, { fontSize: '4rem' })}
                                </Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: COLORS.textPrimary }}>
                                    {service.name}
                                </Typography>
                                <Typography variant="body2" sx={{ color: COLORS.textSecondary, mt: 1 }}>
                                    {service.description}
                                </Typography>
                            </Paper>
                        </Box>
                    ))}
                </Slider>
            </Grid>
        </Grid>
    );

    // Main section rendering function
    const renderSection = (data, dataType) => (
        <Box sx={{ py: 10, px: 10, backgroundColor: COLORS.backgroundGray }}>
            <Grid container spacing={5} justifyContent="center">
                {data.map((category) => renderCategoryItem(category, dataType))}
            </Grid>
        </Box>
    );

    return (
        <>
            <Banner
                title="Our Tech Stack"
                subtitle="Explore the technologies that power our solutions and keep us at the forefront of innovation."
                buttonText="Book Free 15 Minutes Consultation Meeting"
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, backgroundColor: COLORS.backgroundGray }}>
                <Button
                    variant={activeSection === 'languageFrameworks' ? 'contained' : 'outlined'}
                    onClick={() => setActiveSection('languageFrameworks')}
                    sx={buttonStyles}
                >
                    Language Frameworks
                </Button>
                <Button
                    variant={activeSection === 'toolsDomain' ? 'contained' : 'outlined'}
                    onClick={() => setActiveSection('toolsDomain')}
                    sx={buttonStyles}
                >
                    Tools Domain
                </Button>
            </Box>

            {activeSection === 'languageFrameworks' && renderSection(languageFrameworks, 'frameworks')}
            {activeSection === 'toolsDomain' && renderSection(toolsDomain, 'tools')}
        </>
    );
};

export default Tech;

