// WhyChooseUs.js
import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { People, CheckCircle, ThumbUp, Lightbulb } from '@mui/icons-material';

const reasons = [
    {
        title: 'Expert Team',
        description: 'Our team brings deep expertise in modern technologies and top industry practices.',
        icon: <People sx={{ fontSize: 40, color: '#057DD1' }} />,
    },
    {
        title: 'Quality Service',
        description: 'We focus on delivering quality, ensuring premium services for your business.',
        icon: <CheckCircle sx={{ fontSize: 40, color: '#057DD1' }} />,
    },
    {
        title: 'Client Satisfaction',
        description: 'We prioritize your satisfaction, consistently exceeding expectations for optimal results.',
        icon: <ThumbUp sx={{ fontSize: 40, color: '#057DD1' }} />,
    },
    {
        title: 'Innovative Solutions',
        description: 'We create custom, innovative solutions designed to meet your specific requirements.',
        icon: <Lightbulb sx={{ fontSize: 40, color: '#057DD1' }} />,
    },
];

const WhyChooseUs = () => (
    <Box
        sx={{
            py: 6,
            px: 4,
            backgroundColor: '#E6E6E6',
            fontFamily: 'Playwrite New Zealand',
        }}
    >
        <Typography
            variant="h4"
            sx={{
                textAlign: 'center',
                mb: 4,
                fontWeight: 'bold',
                color: '#030519',
            }}
        >
            Why Choose Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
            {reasons.map((reason, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            textAlign: 'center',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
                            },
                        }}
                    >
                        {reason.icon}
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mt: 2 }}>
                            {reason.title}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1, color: '#182759' }}>
                            {reason.description}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    </Box>
);

export default WhyChooseUs;
