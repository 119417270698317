// LanguageCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { FaPython, FaJava, FaJs, FaPhp, FaSwift, FaRust } from 'react-icons/fa';
import { SiTypescript, SiCsharp, SiKotlin, SiRuby, SiR, SiMysql, SiDart, SiScala, SiGnubash, SiJulia, SiElixir,
         SiHaskell } from 'react-icons/si';
import { FaGolang } from "react-icons/fa6";

// Define the programming languages data
export const languages = [
    { name: 'Python', icon: <FaPython />, description: 'Popular for web, data science, ML, and automating tasks in development.' },
    { name: 'JavaScript', icon: <FaJs />, description: 'Vital for web front-end (React) and back-end (Node.js), widely adopted in tech.' },
    { name: 'TypeScript', icon: <SiTypescript />, description: 'JavaScript superset with static typing, favored in large-scale application dev.' },
    { name: 'Java', icon: <FaJava />, description: 'Dominates enterprise apps, backend, and Android; stable and widely implemented.' },
    { name: 'Kotlin', icon: <SiKotlin />, description: 'Android’s modern choice over Java; concise, safer, and easy-to-read language.' },
    { name: 'Swift', icon: <FaSwift />, description: 'Built for iOS/macOS development, Swift powers Apple apps with high performance.' },
    { name: 'Rust', icon: <FaRust />, description: 'Performance-focused with memory safety, used in system programming and beyond.' },
    { name: 'Go', icon: <FaGolang />, description: 'Google’s language for backend, microservices, and cloud with a simple syntax.' },
    { name: 'Ruby', icon: <SiRuby />, description: 'Used with Rails for web dev, praised for its simplicity and productivity.' },
    { name: 'PHP', icon: <FaPhp />, description: 'Important for CMSs like WordPress, PHP remains a web development staple.' },
    { name: 'SQL', icon: <SiMysql />, description: 'Database language key for data management and analytics, not general-purpose.' },
    { name: 'R', icon: <SiR />, description: 'Focuses on stats, data science, and research; a mainstay in academic fields.' },
    { name: 'Dart', icon: <SiDart />, description: 'Used with Flutter, Google’s framework for cross-platform mobile applications.' },
    { name: 'C#', icon: <SiCsharp />, description: 'Popular in enterprise, Windows, and game dev with Unity, a versatile language.' },
    { name: 'Scala', icon: <SiScala />, description: 'Functional and object-oriented, used in data processing and Spark applications.' },
    { name: 'Shell scripting (Bash)', icon: <SiGnubash />, description: 'Vital for automating DevOps, managing systems, and command-line tasks.' },
    { name: 'Julia', icon: <SiJulia />, description: 'Ideal for scientific computing, fast in high-performance computing and research.' },
    { name: 'Elixir', icon: <SiElixir />, description: 'Known for concurrency, used in real-time, resilient apps like messaging systems.' },
    { name: 'Haskell', icon: <SiHaskell />, description: 'Favored in academia, reliable and functional for critical application needs.' },
];


const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 4 } },
        { breakpoint: 768, settings: { slidesToShow: 3 } },
        { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
};

const LanguagesCarousel = () => {

    return (
        <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
            <Slider {...settings}>
                {languages.map((language, index) => (
                    <div key={index} className="slide">
                        <div className="slide-icon">{language.icon}</div>
                        <div className="slide-name">{language.name}</div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LanguagesCarousel;
