import React from 'react';
import { Box, Typography, Grid, Link, IconButton } from '@mui/material';
import { Facebook, LinkedIn, Instagram, WhatsApp, Email } from '@mui/icons-material';

// Footer component with responsive design
const Footer = () => (
    <Box
        sx={{
            py: { xs: 4, sm: 6 },
            px: { xs: 2, sm: 4 },
            backgroundColor: '#030519',
            color: '#fff',
            fontFamily: 'Roboto, Arial, sans-serif',
        }}
    >
        <Grid container spacing={{ xs: 4, sm: 6 }} justifyContent="center">

            {/* Company Info */}
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Atomic System InfoTech Solutions
                </Typography>
                <Typography variant="body2" sx={{ mb: 3, lineHeight: 1.6 }}>
                    Providing innovative solutions to elevate your business to the next level.
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton href="www.facebook.com/share/p/v3AcNtMYPN5311oo/" color="inherit" aria-label="Facebook">
                        <Facebook />
                    </IconButton>
                    <IconButton href="https://www.linkedin.com/feed/update/urn:li:activity:7230772410185056257" color="inherit" aria-label="LinkedIn">
                        <LinkedIn />
                    </IconButton>
                    <IconButton href="https://www.instagram.com/p/C-y5-p5oBB1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" color="inherit" aria-label="Instagram">
                        <Instagram />
                    </IconButton>
                    <IconButton href="https://wa.me/923071225906" color="inherit" aria-label="WhatsApp">
                        <WhatsApp />
                    </IconButton>
                    <IconButton href="mailto:atomicsysitsolutions@gmail.com" color="inherit" aria-label="Gmail">
                        <Email />
                    </IconButton>
                </Box>
            </Grid>

            {/* Quick Links */}
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Quick Links
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Link
                        href="/"
                        color="inherit"
                        underline="hover"
                        variant="body2"
                        sx={{ mb: 1.2, lineHeight: 1.6 }}
                    >
                        Home
                    </Link>
                    <Link
                        href="/about-us"
                        color="inherit"
                        underline="hover"
                        variant="body2"
                        sx={{ mb: 1.2, lineHeight: 1.6 }}
                    >
                        About Us
                    </Link>
                    <Link
                        href="/services"
                        color="inherit"
                        underline="hover"
                        variant="body2"
                        sx={{ mb: 1.2, lineHeight: 1.6 }}
                    >
                        Services
                    </Link>
                    <Link
                        href="/careers"
                        color="inherit"
                        underline="hover"
                        variant="body2"
                        sx={{ mb: 1.2, lineHeight: 1.6 }}
                    >
                        Careers
                    </Link>
                </Box>
            </Grid>

            {/* Contact Us */}
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Contact Us
                </Typography>
                <Typography variant="body2" sx={{ mb: 1.2, lineHeight: 1.6 }}>
                    Office 1: Coming Soon In Islamabad
                </Typography>
                <Typography variant="body2" sx={{ mb: 1.2, lineHeight: 1.6 }}>
                    Office 2: Coming Soon In Karachi
                </Typography>
                <Typography variant="body2" sx={{ mb: 1.2, lineHeight: 1.6 }}>
                    Phone: +92 309 1225906
                </Typography>
                <Typography variant="body2" sx={{ lineHeight: 1.6 }}>
                    Email: atomicsysitsolutions@gmail.com
                </Typography>
            </Grid>

            {/* Embedded Map */}
            <Grid item xs={12} sm={6} md={3}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Our Offices
                </Typography>
                <Box
                    component="iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093745!2d144.95373531531695!3d-37.81720997975192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f3f0e23b%3A0x4ed0b8f7c56c7bb1!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sus!4v1632764672600!5m2!1sen!2sus"
                    width="100%"
                    height="200"
                    loading="lazy"
                    style={{ border: 0, borderRadius: 4 }}
                    allowFullScreen
                />
            </Grid>
        </Grid>

        {/* Footer Bottom Text */}
        <Box sx={{ textAlign: 'center', mt: 4, fontSize: '0.9rem' }}>
            <Typography variant="body2">
                &copy; {new Date().getFullYear()} Atomic System InfoTech Solutions. All rights reserved.
            </Typography>
        </Box>
    </Box>
);

export default Footer;
