import React from 'react';
import { Box, Typography, Grid, Paper, Avatar, IconButton } from '@mui/material';
import { Security, ThumbUp, Lightbulb, People, BusinessCenter, Web, Facebook, LinkedIn, Instagram, CheckCircle } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Banner from "../components/Banner";

// Custom theme for Playfair Display font
const theme = createTheme({
    typography: {
        fontFamily: 'Playfair Display, sans-serif',
    },
});

// Core Values data
const values = [
    { icon: <Security fontSize="large" color="primary" />, title: 'Integrity', description: 'We uphold honesty and transparency in all our actions.' },
    { icon: <ThumbUp fontSize="large" color="primary" />, title: 'Quality', description: 'Our goal is to exceed expectations by delivering exceptional solutions.' },
    { icon: <Lightbulb fontSize="large" color="primary" />, title: 'Innovation', description: 'We strive to bring new ideas and creativity to every project.' },
    { icon: <People fontSize="large" color="primary" />, title: 'Collaboration', description: 'Teamwork and partnerships are at the heart of our success.' },
];

// Leadership data
const leaders = [
    {
        name: 'Wajahat Bhatti',
        role: 'Founder & CTO',
        bio: 'Passionate tech leader with a fresh perspective in the industry. Skilled in leveraging emerging tech to build innovative solutions that drive growth.',
        photo: './images/founder.jpg',
        socialLinks: {
            facebook: 'https://facebook.com/wajahatbhatti',
            linkedin: 'https://linkedin.com/in/wajahatbhatti',
            instagram: 'https://instagram.com/wajahatbhatti',
        },
    },
    {
        name: 'Adeena Hussain',
        role: 'Co-Founder & CEO',
        bio: 'Dynamic leader with a vision for growth. A recent graduate with a drive to build impactful solutions in the tech space, inspiring change.',
        photo: '/images/john.jpg',
        socialLinks: {
            facebook: 'https://facebook.com/adeenahussain',
            linkedin: 'https://linkedin.com/in/adeenahussain',
            instagram: 'https://instagram.com/adeenahussain',
        },
    },
];

// Why Choose Us data
const reasons = [
    {
        title: 'Expert Team',
        description: 'Our skilled team brings diverse expertise, allowing us to tackle challenges effectively.',
        icon: <People sx={{ fontSize: 50, color: '#057DD1' }} />,
    },
    {
        title: 'Quality Service',
        description: 'Our rigorous assurance processes ensure every project meets high standards.',
        icon: <CheckCircle sx={{ fontSize: 50, color: '#057DD1' }} />,
    },
    {
        title: 'Client Satisfaction',
        description: 'We build long-term client partnerships with open communication and transparency.',
        icon: <ThumbUp sx={{ fontSize: 50, color: '#057DD1' }} />,
    },
    {
        title: 'Innovative Solutions',
        description: 'We create tailored solutions with advanced tools for real business value.',
        icon: <Lightbulb sx={{ fontSize: 50, color: '#057DD1' }} />,
    },
];

const AboutUs = () => (
    <ThemeProvider theme={theme}>
        <>
            <Banner
                title={'Who We Are'}
                subtitle={'Driving innovation and delivering excellence across industries. Learn about our journey, values, and commitment to quality'}
                buttonText={'Book Free 15 Minutes Consultation Meeting'}
            />
            {/* About Us Section */}
            <Box sx={{ py: 6, px: 4, backgroundColor: '#E6E6E6', color: '#030519' }}>
                <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4, color: '#030519' }}>
                    About Us
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        maxWidth: '700px',
                        mx: 'auto',
                        textAlign: 'center',
                        color: '#030519',
                        mb: 6,
                        lineHeight: 1.75,
                    }}
                >
                    At Atomic System InfoTech Solutions, we are committed to delivering innovative technology solutions that drive success and empower businesses globally.
                </Typography>

                {/* Mission and Vision Section */}
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} md={5}>
                        <Paper elevation={3} sx={{ p: 5, textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: 2 }}>
                            <BusinessCenter sx={{ fontSize: 60, color: '#075DD1', mb: 2 }} />
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#030519', mb: 1 }}>Our Mission</Typography>
                            <Typography variant="body1" sx={{ color: '#182759', lineHeight: 1.6 }}>
                                To deliver cutting-edge solutions that transform businesses, fostering growth and innovation.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Paper elevation={3} sx={{ p: 5, textAlign: 'center', backgroundColor: '#FFFFFF', borderRadius: 2 }}>
                            <Web sx={{ fontSize: 60, color: '#075DD1', mb: 2 }} />
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#030519', mb: 1 }}>Our Vision</Typography>
                            <Typography variant="body1" sx={{ color: '#182759', lineHeight: 1.6 }}>
                                To be a global leader in IT solutions, enhancing businesses’ digital future.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Core Values Section */}
            <Box sx={{ px: 4, py: 6, backgroundColor: '#E6E6E6' }}>
                <Typography variant="h4" sx={{ textAlign: 'center', mb: 6, fontWeight: 'bold', color: '#030519' }}>
                    Our Core Values
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {values.map((value, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper sx={{ p: 4, textAlign: 'center', boxShadow: 3, backgroundColor: '#FFFFFF', borderRadius: 2 }}>
                                <Box sx={{ mb: 2 }}>{value.icon}</Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>{value.title}</Typography>
                                <Typography variant="body2" sx={{ color: '#182759', lineHeight: 1.6 }}>{value.description}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Leadership Section */}
            <Box sx={{ py: 4, px: 2, backgroundColor: '#E6E6E6' }}>
                <Typography variant="h4" sx={{ textAlign: 'center', mb: 5, fontWeight: 'bold', color: '#030519' }}>
                    Leadership
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {leaders.map((leader, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper sx={{ p: 4, textAlign: 'center', boxShadow: 3, borderRadius: 2 }}>
                                <Avatar
                                    src={leader.photo}
                                    alt={leader.name}
                                    sx={{ width: 120, height: 120, margin: 'auto', mb: 3, border: '2px solid #075DD1' }}
                                />
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>{leader.name}</Typography>
                                <Typography variant="subtitle1" sx={{ color: '#075DD1', mb: 1 }}>{leader.role}</Typography>
                                <Typography variant="body2" sx={{ color: '#182759', lineHeight: 1.6 }}>{leader.bio}</Typography>
                                {/*<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>*/}
                                {/*    <IconButton aria-label="facebook" href={leader.socialLinks.facebook} target="_blank" sx={{ color: '#3b5998' }}><Facebook /></IconButton>*/}
                                {/*    <IconButton aria-label="linkedin" href={leader.socialLinks.linkedin} target="_blank" sx={{ color: '#0077b5' }}><LinkedIn /></IconButton>*/}
                                {/*    <IconButton aria-label="instagram" href={leader.socialLinks.instagram} target="_blank" sx={{ color: '#E1306C' }}><Instagram /></IconButton>*/}
                                {/*</Box>*/}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Why Choose Us Section */}
            <Box sx={{ py: 4, px: 2, backgroundColor: '#E6E6E6' }}>
                <Typography variant="h4" sx={{ textAlign: 'center', mb: 4, fontWeight: 'bold', color: '#030519' }}>
                    Why Choose Us
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    {reasons.map((reason, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Paper sx={{ p: 4, textAlign: 'center', boxShadow: 3, backgroundColor: '#FFFFFF', borderRadius: 2 }}>
                                <Box sx={{ mb: 2 }}>{reason.icon}</Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>{reason.title}</Typography>
                                <Typography variant="body2" sx={{ color: '#182759', lineHeight: 1.6 }}>{reason.description}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    </ThemeProvider>
);

export default AboutUs;
