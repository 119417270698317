// ContactUs.js
import React from 'react';
import Banner from '../components/Banner';
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export const ICON_HOVER_ANIMATION = {
    hover: { scale: 1.2, transition: { duration: 0.3 } },
};

export const PRIMARY_COLOR = '#030519';
export const socialLinks = [
    {
        icon: <FaFacebook />,
        label: 'Facebook',
        url: 'https://www.facebook.com/share/p/v3AcNtMYPN5311oo/',
        color: '#3b5998'
    },
    {
        icon: <FaLinkedin />,
        label: 'LinkedIn',
        url: 'https://www.linkedin.com/feed/update/urn:li:activity:7230772410185056257',
        color: '#0077b5'
    },
    {
        icon: <FaInstagram />,
        label: 'Instagram',
        url: 'https://www.instagram.com/p/C-y5-p5oBB1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
        color: '#E4405F'
    },
    {
        icon: <FaWhatsapp />,
        label: 'WhatsApp',
        url: 'https://wa.me/923071225906',
        color: '#25D366'
    },
    {
        icon: <MdEmail />,
        label: 'Email',
        url: 'mailto:atomicsysitsolutions@gmail.com',
        color: '#D44638'
    },
];

const Contact = () => {
    return (
        <>
            <Banner
                title={'For More Details'}
                subtitle={
                    'Get in touch! Whether you have questions, need support, or want to collaborate, reach out through any platform – or visit our office. We’re here to connect!'
                }
                buttonText={'Book Free 15 Minutes Consultation Meeting'}
            />

            <Box sx={{ px: { xs: 4, md: 10 }, py: 6, backgroundColor: '#E6E6E6', textAlign: 'center' }}>
                <Typography variant="h4" sx={{ mb: 4, color: PRIMARY_COLOR, fontWeight: 'bold' }}>
                    Get in Touch
                </Typography>
                <Typography variant="body1" sx={{ mb: 6, color: '#4F4F4F', maxWidth: 700, mx: 'auto' }}>
                    Connect with us on social media, drop us an email, or visit us at our office. We're here to help you.
                </Typography>

                {/* Social Icons */}
                <Grid container spacing={3} justifyContent="center" sx={{ mb: 8 }}>
                    {socialLinks.map((social, index) => (
                        <Grid item key={index}>
                            <IconButton
                                component="a"
                                href={social.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: social.color, fontSize: 40 }}
                            >
                                <motion.div variants={ICON_HOVER_ANIMATION} whileHover="hover">
                                    {social.icon}
                                </motion.div>
                            </IconButton>
                            <Typography variant="caption" sx={{ display: 'block', color: '#4F4F4F' }}>
                                {social.label}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: PRIMARY_COLOR }}>
                        Visit Us
                    </Typography>

                    <Paper
                        sx={{
                            height: 500,
                            overflow: 'hidden',
                            boxShadow: 3,
                            borderRadius: 2,
                            '&:hover': { boxShadow: 6 },
                        }}
                    >
                        <iframe
                            title="office-location"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509374!2d144.9537353155045!3d-37.81720974201052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f5f288b3%3A0x4a8c6e1e3f26e428!2sGoogle!5e0!3m2!1sen!2sus!4v1615942554247!5m2!1sen!2sus"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </Paper>
                </Grid>
            </Box>
        </>
    );
};

export default Contact;
