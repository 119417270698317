// Home.js
import React from 'react';
import Banner from '../components/Banner';
import Introduction from "../components/home/Introduction";
import ServiceCarousel from "../components/carouselMini/ServicesCarousel";
import LanguagesCarousel from "../components/carouselMini/LanguagesCarousel";
import WhyUs from "../components/home/WhyUs";
import FrameworkCarousel from "../components/carouselMini/FrameworkCarousel";
import ProductOverview from "../components/home/ProductsOverview";

const Home = () => {
  return (
      <>
        <Banner
            title={'Innovative Solutions for Tomorrow’s Challenges'}
            subtitle={'Empowering your business with cutting-edge technology and expert-driven solutions'}
            buttonText={'Book Free 15 Minutes Consultation Meeting'}
        />
        <ServiceCarousel />
        <Introduction />
        <LanguagesCarousel />
        <WhyUs />
        <FrameworkCarousel />
        <ProductOverview />

      </>
  );
};

export default Home;
