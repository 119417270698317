import {Box, Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import Banner from "../components/Banner";
import React from "react";
import Slider from "react-slick";
import {
    FaBook,
    FaBrain,
    FaBriefcase,
    FaBug,
    FaBuilding,
    FaChalkboard,
    FaChartBar,
    FaChartLine,
    FaClipboard,
    FaClipboardList,
    FaCloud,
    FaCloudSun,
    FaCloudUploadAlt,
    FaCode, FaCodeBranch,
    FaCogs,
    FaCommentAlt,
    FaCommentDots,
    FaComments,
    FaDatabase,
    FaDesktop,
    FaDocker,
    FaDrupal,
    FaFileCode, FaFireAlt,
    FaFlask,
    FaGhost,
    FaGit,
    FaGithub,
    FaHandshake, FaHome,
    FaJoomla, FaLaptopCode,
    FaLock, FaMagento, FaNetworkWired,
    FaReact, FaRobot,
    FaRocket,
    FaServer,
    FaShoppingBasket, FaShoppingCart,
    FaStickyNote,
    FaTasks,
    FaUserFriends,
    FaUsers,
    FaUsersCog, FaWarehouse,
    FaWordpress,
    FaWrench
} from "react-icons/fa";
import CloudIcon from "@mui/icons-material/Cloud";
import BuildIcon from "@mui/icons-material/Build";
import WebIcon from "@mui/icons-material/Web";
import {FaMessage, FaPeopleGroup} from "react-icons/fa6";

const productCategories = [
    {
        name: 'E-Commerce',
        icon: <FaShoppingCart />,
        description: 'Versatile e-commerce platforms for managing online stores, handling inventory, and processing payments.',
        tools: [
            { name: 'OpenCart', icon: <FaShoppingCart />, description: 'An open-source e-commerce solution known for easy customization and SEO-friendliness.' },
            { name: 'Magento', icon: <FaMagento />, description: 'A powerful platform for large-scale e-commerce with advanced analytics and inventory management.' },
            { name: 'WooCommerce', icon: <FaWordpress />, description: 'A WordPress plugin that adds e-commerce functionality with extensive plugins and themes.' },
            { name: 'Bagisto', icon: <FaShoppingBasket />, description: 'A Laravel-based e-commerce platform designed for customizability and scalability.' },
            { name: 'Sylius', icon: <FaCogs />, description: 'A PHP-based, customizable e-commerce framework for tailored online store solutions.' }
        ]
    },
    {
        name: 'ERP',
        icon: <FaHome />,
        description: 'Enterprise Resource Planning tools for business management, including HR, finance, and supply chain modules.',
        tools: [
            { name: 'ERPNext', icon: <FaWarehouse />, description: 'A free and open-source ERP system designed for ease of use in small and medium enterprises.' },
            { name: 'Odoo', icon: <FaBuilding />, description: 'A suite of open-source ERP applications, covering CRM, sales, inventory, and project management.' },
            { name: 'Dolibarr', icon: <FaBriefcase />, description: 'An ERP and CRM software designed for small businesses, freelancers, and associations.' },
            { name: 'Tryton', icon: <FaClipboard />, description: 'A general-purpose, three-tier ERP with a modular design for easy customizability.' },
            { name: 'iDempiere', icon: <FaCogs />, description: 'A community-driven ERP and CRM solution focused on flexibility and extensibility.' }
        ]
    },
    {
        name: 'CMS Platforms',
        icon: <WebIcon />,
        description: 'Content Management Systems for managing and publishing website content without extensive coding.',
        tools: [
            { name: 'WordPress', icon: <FaWordpress />, description: 'A widely used CMS for blogs and websites, known for its large plugin ecosystem.' },
            { name: 'Joomla!', icon: <FaJoomla />, description: 'A flexible CMS for complex websites, with robust security and customization options.' },
            { name: 'Drupal', icon: <FaDrupal />, description: 'A powerful CMS for creating enterprise-level websites and applications.' },
            { name: 'Ghost', icon: <FaGhost />, description: 'A modern CMS focused on publishing and content creation, with a minimalist design.' },
            { name: 'Hugo', icon: <FaGit />, description: 'A fast, static site generator that’s optimized for speed and large-scale websites.' }
        ]
    },
    {
        name: 'Data Analytics and BI',
        icon: <FaChartBar />,
        description: 'Business Intelligence tools for analyzing data, creating visualizations, and generating actionable insights.',
        tools: [
            { name: 'Apache Superset', icon: <FaChartBar />, description: 'An open-source data visualization and dashboard platform with SQL support.' },
            { name: 'Metabase', icon: <FaDatabase />, description: 'An intuitive BI tool for data visualization, suited for non-technical users.' },
            { name: 'Redash', icon: <FaServer />, description: 'A web-based tool for querying databases and creating dynamic dashboards.' },
            { name: 'Grafana', icon: <FaChartLine />, description: 'A popular analytics tool for monitoring time-series data and logging analytics.' },
            { name: 'Apache Zeppelin', icon: <FaCloud />, description: 'A web-based notebook that enables data-driven, interactive data analysis.' }
        ]
    },
    {
        name: 'CRM Solutions',
        icon: <FaPeopleGroup />,
        description: 'Customer Relationship Management platforms to manage interactions, track leads, and optimize customer service.',
        tools: [
            { name: 'SuiteCRM', icon: <FaUsersCog />, description: 'An open-source CRM with features for sales, marketing, and customer support.' },
            { name: 'EspoCRM', icon: <FaUserFriends />, description: 'A web-based CRM for tracking relationships and streamlining customer communications.' },
            { name: 'CiviCRM', icon: <FaHandshake />, description: 'A CRM solution aimed at nonprofits for managing members, contacts, and donations.' },
            { name: 'Vtiger', icon: <FaUsers />, description: 'An all-in-one CRM for sales, support, and marketing teams with robust automation.' }
        ]
    },
    {
        name: 'DevOps Tools',
        icon: <BuildIcon />,
        description: 'DevOps platforms for continuous integration, automation, and collaboration across development and operations.',
        tools: [
            { name: 'Kubernetes', icon: <FaDocker />, description: 'An orchestration platform for deploying, managing, and scaling containerized applications.' },
            { name: 'Ansible', icon: <FaFileCode />, description: 'An automation tool for configuration management and application deployment.' },
            { name: 'Terraform', icon: <FaCloudSun />, description: 'An infrastructure as code tool for managing cloud resources and services.' },
            { name: 'Prometheus', icon: <FaDatabase />, description: 'A monitoring tool that records metrics and generates real-time alerts.' },
            { name: 'Jenkins', icon: <FaGithub />, description: 'An open-source CI/CD tool for automating software development pipelines.' }
        ]
    },
    {
        name: 'Project Management',
        icon: <FaTasks />,
        description: 'Tools for managing projects, tracking tasks, and improving team collaboration and productivity.',
        tools: [
            { name: 'Taiga', icon: <FaTasks />, description: 'An agile project management tool with support for Kanban and Scrum workflows.' },
            { name: 'Redmine', icon: <FaBug />, description: 'A flexible project management web app with Gantt charts and time tracking.' },
            { name: 'OpenProject', icon: <FaClipboardList />, description: 'A project collaboration software for issue tracking and timeline planning.' },
            { name: 'Focalboard', icon: <FaChalkboard />, description: 'A task management tool for individuals and teams, optimized for simplicity.' }
        ]
    },
    {
        name: 'Knowledge Management',
        icon: <FaBook />,
        description: 'Platforms to organize, share, and manage company knowledge, documentation, and resources.',
        tools: [
            { name: 'Wiki.js', icon: <FaWrench />, description: 'A modern wiki software that’s easy to set up and supports multiple databases.' },
            { name: 'BookStack', icon: <FaBook />, description: 'An open-source documentation platform for managing knowledge in a structured way.' },
            { name: 'Docusaurus', icon: <FaReact />, description: 'A documentation generator designed for creating easy-to-maintain docs websites.' },
            { name: 'Read the Docs', icon: <FaCode />, description: 'A service for creating and hosting documentation online, mainly for open-source projects.' },
            { name: 'Joplin', icon: <FaStickyNote />, description: 'An open-source note-taking app with support for markdown and synchronization.' }
        ]
    },
    {
        name: 'Collaboration',
        icon: <FaMessage />,
        description: 'Tools for real-time communication and collaboration, enabling teams to work together effectively.',
        tools: [
            { name: 'Mattermost', icon: <FaCommentDots />, description: 'A secure, self-hosted messaging tool for team communication and collaboration.' },
            { name: 'Rocket.Chat', icon: <FaRocket />, description: 'An open-source team chat platform with video and audio support.' },
            { name: 'Zulip', icon: <FaCommentAlt />, description: 'A threaded messaging app that organizes conversations by topic.' },
            { name: 'Element', icon: <FaLock />, description: 'A secure messaging app with end-to-end encryption and decentralized networks.' },
            { name: 'Discourse', icon: <FaComments />, description: 'A discussion platform built for modern online forums and communities.' }
        ]
    },
    {
        name: 'Cloud Management',
        icon: <CloudIcon />,
        description: 'Platforms for managing cloud infrastructure, virtual machines, and containers in hybrid and multi-cloud environments.',
        tools: [
            { name: 'OpenStack', icon: <FaCloud />, description: 'A cloud infrastructure platform for managing compute, storage, and networking resources.' },
            { name: 'Proxmox VE', icon: <FaCloudUploadAlt />, description: 'A virtualization management platform for virtual machines and containers.' },
            { name: 'Terraform', icon: <FaCloudSun />, description: 'An infrastructure as code tool for provisioning and managing cloud infrastructure.' },
            { name: 'Rancher', icon: <FaCogs />, description: 'A container management platform for running Kubernetes clusters.' },
            { name: 'KVM', icon: <FaDesktop />, description: 'An open-source virtualization technology for running virtual machines on Linux.' }
        ]
    },
    {
        name: 'Machine Learning',
        icon: <FaBrain />,
        description: 'Platforms and frameworks for building, training, and deploying machine learning models and AI applications.',
        tools: [
            { name: 'TensorFlow', icon: <FaCode />, description: 'An open-source library for deep learning and complex neural network models.' },
            { name: 'PyTorch', icon: <FaLaptopCode />, description: 'A machine learning framework for building and deploying AI applications.' },
            { name: 'Apache Spark', icon: <FaFireAlt />, description: 'A unified analytics engine for big data processing with built-in machine learning.' },
            { name: 'Jupyter', icon: <FaBook />, description: 'An open-source notebook for creating and sharing documents with live code.' },
            { name: 'Keras', icon: <FaBrain />, description: 'An API for building neural networks, designed for quick experimentation.' }
        ]
    }
];




const Products = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : 2,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <>
            <Banner
                title={'Our Open Source Products'}
                subtitle={'Top-rated, customizable open-source solutions'}
                buttonText={'Book Free 15 Minutes Consultation Meeting'}
            />

            <Box sx={{ py: 10, px: 10, backgroundColor: '#E6E6E6' }}>
                <Grid container spacing={5} justifyContent="center">
                    {productCategories.map((category, index) => (
                        <Grid container spacing={2} key={index} sx={{ mb: 6 }} alignItems="center">
                            <Grid item xs={12} md={3}>
                                <Paper elevation={4} sx={{ p: 3, textAlign: 'center', height: '100%' }}>
                                    <Box sx={{ fontSize: '3rem', color: '#075DD1', mb: 2 }}>
                                        {React.cloneElement(category.icon, { fontSize: '4rem' })}
                                    </Box>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>
                                        {category.name}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ color: '#182759', fontStyle: 'italic' }}>
                                        {category.description}
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Slider {...sliderSettings}>
                                    {category.tools.map((service, i) => (
                                        <Box key={i}>
                                            <Paper elevation={3} sx={{ p: 4, textAlign: 'center', minHeight: '130px', margin: 1, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)' }}>
                                                <Box sx={{ fontSize: '3rem', color: '#075DD1', mb: 2 }}>
                                                    {React.cloneElement(service.icon, { fontSize: '4rem' })}
                                                </Box>
                                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519' }}>
                                                    {service.name}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#182759', mt: 1 }}>
                                                    {service.description}
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    ))}
                                </Slider>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default Products;