// FrameworkCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { SiActix, SiAndroid, SiAngular, SiAnsible, SiApache, SiBlazor, SiCodeigniter, SiDbt, SiDjango, SiExpress,
    SiFastapi, SiFlask, SiFlutter, SiFlux, SiGin, SiHibernate, SiKtor, SiKubernetes, SiLaravel, SiNestjs,
    SiNextdotjs, SiPandas, SiPresto, SiPytorch, SiQuarkus, SiReact, SiRemix, SiRocket, SiSpringboot, SiSvelte,
    SiSwift, SiSymfony, SiTensorflow, SiTide, SiUnity, SiVapor, SiVuedotjs, SiXamarin } from "react-icons/si";

export const frameworksAndLibraries = [
    // Python
    { name: 'Django', icon: <SiDjango />, description: 'A high-level Python framework for fast, secure web app development.' },
    { name: 'Flask', icon: <SiFlask />, description: 'A lightweight, flexible web framework in Python for rapid development.' },
    { name: 'FastAPI', icon: <SiFastapi />, description: 'A high-performance Python framework for modern, fast API development.' },
    { name: 'TensorFlow', icon: <SiTensorflow />, description: 'An open-source ML and AI framework, widely used for deep learning.' },
    { name: 'PyTorch', icon: <SiPytorch />, description: 'A flexible, open-source library for machine learning and deep learning.' },
    { name: 'Pandas', icon: <SiPandas />, description: 'A Python library for data analysis with high-performance data structures.' },

    // JavaScript
    { name: 'React', icon: <SiReact />, description: 'A library for building UI components, mainly for single-page web apps.' },
    { name: 'Vue.js', icon: <SiVuedotjs />, description: 'A progressive framework for building UIs, known for simplicity.' },
    { name: 'Angular', icon: <SiAngular />, description: 'A TypeScript-based framework for building dynamic single-page apps.' },
    { name: 'Next.js', icon: <SiNextdotjs />, description: 'A React framework for building fast, optimized web applications.' },
    { name: 'Express.js', icon: <SiExpress />, description: 'A web framework for Node.js, widely used to build APIs and apps.' },
    { name: 'Svelte', icon: <SiSvelte />, description: 'A UI framework that compiles components to highly efficient JavaScript.' },

    // TypeScript
    { name: 'NestJS', icon: <SiNestjs />, description: 'A progressive framework for scalable and efficient server-side apps.' },
    { name: 'Next.js (TypeScript)', icon: <SiNextdotjs />, description: 'A React framework with TypeScript support for quick, smooth apps.' },
    { name: 'Angular (TypeScript)', icon: <SiAngular />, description: 'A TypeScript-based framework for scalable, maintainable web apps.' },
    { name: 'Remix', icon: <SiRemix />, description: 'A fast framework for creating modern web applications with React.' },

    // Java
    { name: 'Spring Boot', icon: <SiSpringboot />, description: 'A framework for building Java apps with embedded servers, no XML.' },
    { name: 'Hibernate', icon: <SiHibernate />, description: 'A tool for mapping Java objects to relational databases with ease.' },
    { name: 'Apache Struts', icon: <SiApache />, description: 'A Java framework for building web apps using the MVC architecture.' },
    { name: 'Quarkus', icon: <SiQuarkus />, description: 'A Java framework optimized for cloud-native apps, using GraalVM.' },

    // Kotlin
    { name: 'Ktor', icon: <SiKtor />, description: 'A flexible, asynchronous Kotlin framework for building web apps.' },
    { name: 'Spring Boot (Kotlin)', icon: <SiSpringboot />, description: 'A Kotlin-friendly Spring Boot for easy backend app development.' },
    { name: 'Android Architecture Components', icon: <SiAndroid />, description: 'Libraries to help design maintainable, robust Android apps.' },

    // Swift
    { name: 'SwiftUI', icon: <SiSwift />, description: 'A declarative framework for building native UIs across Apple devices.' },
    { name: 'Vapor', icon: <SiVapor />, description: 'A server-side Swift framework for building fast, secure web apps.' },

    // Rust
    { name: 'Rocket', icon: <SiRocket />, description: 'A Rust web framework focused on safety, speed, and simplicity.' },
    { name: 'Actix', icon: <SiActix />, description: 'A Rust framework for fast, scalable, actor-based web apps.' },
    { name: 'Tide', icon: <SiTide />, description: 'A minimal Rust framework designed for async, rapid web development.' },

    // Go (Golang)
    { name: 'Gin', icon: <SiGin />, description: 'A minimal, efficient Go framework for fast, scalable web applications.' },

    // Ruby
    // { name: 'Ruby on Rails', icon: <SiRails />, description: 'A web framework emphasizing convention over configuration, using Ruby.' },

    // PHP
    { name: 'Laravel', icon: <SiLaravel />, description: 'A PHP framework with elegant syntax for modern, scalable web apps.' },
    { name: 'Symfony', icon: <SiSymfony />, description: 'A flexible PHP framework for building robust, large web applications.' },
    { name: 'CodeIgniter', icon: <SiCodeigniter />, description: 'A PHP framework for lightweight, rapid application development.' },

    // SQL
    { name: 'Apache Spark SQL', icon: <SiApache />, description: 'A framework for distributed data processing, supporting SQL-based analysis.' },
    { name: 'Presto', icon: <SiPresto />, description: 'A distributed SQL engine for high-speed queries on large datasets.' },
    { name: 'DBT', icon: <SiDbt />, description: 'A transformation framework for analysts, turning raw data into insights.' },

    // Dart
    { name: 'Flutter', icon: <SiFlutter />, description: 'A toolkit for building native apps across mobile, web, and desktop.' },

    // C#
    { name: 'Unity', icon: <SiUnity />, description: 'A cross-platform engine for building 2D/3D games and simulations.' },
    { name: 'Blazor', icon: <SiBlazor />, description: 'A C# framework for building web applications without JavaScript.' },
    { name: 'Xamarin', icon: <SiXamarin />, description: 'A framework for building cross-platform mobile apps using C#.' },

    // Shell Scripting (Bash)
    { name: 'Ansible', icon: <SiAnsible />, description: 'An IT automation tool for configuration management and deployment.' },
    { name: 'Kubernetes Helm', icon: <SiKubernetes />, description: 'A package manager for Kubernetes applications for easy setup.' },

    // Julia
    { name: 'Flux.jl', icon: <SiFlux />, description: 'A Julia library for machine learning, focused on flexibility and power.' },

    // Elixir
    // { name: 'Phoenix', icon: <SiPhoenix />, description: 'A web framework for Elixir, for building scalable, maintainable apps.' },

    // Haskell
    // { name: 'Yesod', icon: <SiYesod />, description: 'A Haskell web framework for secure, maintainable applications.' }
];



export const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {breakpoint: 1024, settings: {slidesToShow: 4}},
        {breakpoint: 768, settings: {slidesToShow: 3}},
        {breakpoint: 480, settings: {slidesToShow: 2}},
    ],
};
const FrameworkCarousel = () => {

    return (
        <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
            <Slider {...settings}>
                {frameworksAndLibraries.map((frameworks, index) => (
                    <div key={index} className="slide">
                        <div className="slide-icon">{frameworks.icon}</div>
                        <div className="slide-name">{frameworks.name}</div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default FrameworkCarousel;