// OurProcess.js
import React from 'react';
import Banner from '../components/Banner';
import { Box, Grid, Paper, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FaChartLine, FaClipboardCheck, FaCog, FaFileCode, FaProjectDiagram, FaRegLightbulb } from "react-icons/fa";
import { AiOutlineDeploymentUnit } from "react-icons/ai";

// Animation Variants
const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

// Unified Process Steps
const processSteps = [
    {
        title: 'Requirement Analysis',
        icon: <FaRegLightbulb />,
        description: 'Gathering all requirements from clients and stakeholders to establish clear objectives and expectations.'
    },
    {
        title: 'Planning',
        icon: <FaProjectDiagram />,
        description: 'Designing a structured roadmap for development, resource allocation, and timeline estimation.'
    },
    {
        title: 'Design',
        icon: <FaCog />,
        description: 'Creating system architecture and UI/UX mockups for an intuitive user experience.'
    },
    {
        title: 'Development',
        icon: <FaFileCode />,
        description: 'Implementing code based on design specifications, ensuring quality and efficiency.'
    },
    {
        title: 'Testing',
        icon: <FaClipboardCheck />,
        description: 'Rigorous testing to identify and resolve any defects, ensuring high reliability and performance.'
    },
    {
        title: 'Sprint Review',
        icon: <FaClipboardCheck />,
        description: 'Presenting completed work to stakeholders for feedback and approval.'
    },
    {
        title: 'Deployment',
        icon: <AiOutlineDeploymentUnit />,
        description: 'Launching the product to the client environment, ensuring smooth integration and functionality.'
    },
    {
        title: 'Maintenance',
        icon: <FaChartLine />,
        description: 'Ongoing support, updates, and enhancements to keep the software performing optimally.'
    },
    {
        title: 'Retrospective',
        icon: <FaChartLine />,
        description: 'Evaluating what went well, what could be improved, and planning enhancements for the next iteration.'
    }
];

// Component for a Single Step
const ProcessStep = ({ step }) => (
    <motion.div variants={fadeIn} initial="hidden" animate="visible">
        <Paper
            sx={{
                p: 4,
                textAlign: 'center',
                minHeight: 200,
                boxShadow: 3,
                transition: 'transform 0.2s',
                '&:hover': { transform: 'scale(1.05)' }
            }}
        >
            <Box sx={{ fontSize: 50, color: '#075DD1', mb: 1 }}>
                {step.icon}
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mb: 1 }}>
                {step.title}
            </Typography>
            <Typography variant="body2" sx={{ color: '#182759' }}>
                {step.description}
            </Typography>
        </Paper>
    </motion.div>
);

const OurProcess = () => {
    return (
        <>
            <Banner
                title={'Our Proven Approach'}
                subtitle={'From concept to completion, our process ensures efficiency, quality, and satisfaction.'}
                buttonText={'Book Free 15 Minutes Consultation Meeting'}
            />

            <Box
                sx={{
                    px: { xs: 4, md: 10 },
                    py: 6,
                    backgroundColor: '#E6E6E6',
                    fontFamily: 'Playwright New Zealand'
                }}
            >
                <Typography variant="h4" align="center" sx={{ mb: 4, color: '#030519', fontWeight: 'bold' }}>
                    Our Unified Process
                </Typography>
                <Typography variant="body1" align="center" sx={{ mb: 4, color: '#182759' }}>
                    We blend the structured approach of the Software Development Life Cycle (SDLC) with the flexibility of Agile methodology to ensure a smooth and efficient project journey.
                </Typography>

                <Grid container spacing={4}>
                    {processSteps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <ProcessStep step={step} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default OurProcess;
