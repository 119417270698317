// productOverview.js
import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import {FaBrain, FaChartBar, FaHome, FaShoppingCart} from "react-icons/fa";
import WebIcon from "@mui/icons-material/Web";
import {FaMessage, FaPeopleGroup} from "react-icons/fa6";
import CloudIcon from "@mui/icons-material/Cloud";


const productCategories = [
    {
        name: 'E-Commerce',
        icon: <FaShoppingCart />,
        description: 'Versatile e-commerce platforms for managing online stores, handling inventory, and processing payments.',
    },
    {
        name: 'ERP',
        icon: <FaHome />,
        description: 'Enterprise Resource Planning tools for business management, including HR, finance, and supply chain modules.',
    },
    {
        name: 'CMS Platforms',
        icon: <WebIcon />,
        description: 'Content Management Systems for managing and publishing website content without extensive coding.',
    },
    {
        name: 'Data Analytics and BI',
        icon: <FaChartBar />,
        description: 'Business Intelligence tools for analyzing data, creating visualizations, and generating actionable insights.',

    },
    {
        name: 'CRM Solutions',
        icon: <FaPeopleGroup />,
        description: 'Customer Relationship Management platforms to manage interactions, track leads, and optimize customer service.',
    },
    {
        name: 'Collaboration',
        icon: <FaMessage />,
        description: 'Tools for real-time communication and collaboration, enabling teams to work together effectively.',
    },
    {
        name: 'Cloud Management',
        icon: <CloudIcon />,
        description: 'Platforms for managing cloud infrastructure, virtual machines, and containers in hybrid and multi-cloud environments.',
    },
    {
        name: 'Machine Learning',
        icon: <FaBrain />,
        description: 'Platforms and frameworks for building, training, and deploying machine learning models and AI applications.',
    }
];

const ProductOverview = () => (
    <Box
        sx={{
            py: 6,
            px: 4,
            backgroundColor: '#E6E6E6',
            fontFamily: 'Playwrite New Zealand',
        }}
    >
        <Typography
            variant="h4"
            sx={{
                textAlign: 'center',
                mb: 4,
                fontWeight: 'bold',
                color: '#030519',
            }}
        >
            Our Products
        </Typography>
        <Grid container spacing={4} justifyContent="center">
            {productCategories.map((reason, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                    <Paper
                        elevation={3}
                        sx={{
                            p: 3,
                            textAlign: 'center',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                transform: 'translateY(-5px)',
                                boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
                            },
                        }}
                    >
                        <Typography sx={{ fontSize: 40, color: '#057DD1' }}>
                            {reason.icon}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#030519', mt: 2 }}>
                            {reason.name}
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1, color: '#182759' }}>
                            {reason.description}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    </Box>
);

export default ProductOverview;
