import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/NavBar';
import Footer from "./components/Footer";

import Home from './pages/Home';

import Products from './pages/Products';
import Services from './pages/Services';
import TechStack from "./pages/TechStack";
import OurProcess from "./pages/OurProcess";
import Career from "./pages/Careers";
import Contact from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";



function App() {
  return (
    
    <Router>
      {/* Navbar will always be visible on every page */}
      <Navbar />
      {/* Define the routes */}
      <Routes>
        {/* Route for Home page */}
        <Route path="/" element={<Home />} />
        {/* Route for About page */}
        <Route path="/about-us" element={<AboutUs />} />
        {/*<Route path="/team" element={<Team />} />*/}
        {/*<Route path="/why-us" element={<WhyUs />} />*/}
        {/* Route for Solutions page */}
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />

        <Route path="/tech-stack" element={<TechStack />} />
        <Route path="/process" element={<OurProcess />} />
        <Route path="/careers" element={<Career />} />

        <Route path="/contact" element={<Contact />} />

      </Routes>
      <Footer />

    </Router>
  );
}

export default App;
