// ServicesCarousel.js
import React from 'react';
import Slider from 'react-slick';
import { FaCode, FaLaptopCode, FaPaintBrush, FaHeadset, FaCloud, FaShieldAlt, FaShoppingCart, FaCogs } from 'react-icons/fa';

const services = [
    { name: 'Development Services', icon: <FaCode /> },
    { name: 'Emerging Technologies', icon: <FaLaptopCode /> },
    { name: 'Design and UX', icon: <FaPaintBrush /> },
    { name: 'IT Support', icon: <FaHeadset /> },
    { name: 'Cloud Consulting', icon: <FaCloud /> },
    { name: 'Cybersecurity', icon: <FaShieldAlt /> },
    { name: 'eCommerce & Marketing', icon: <FaShoppingCart /> },
    { name: 'DevOps & Automation', icon: <FaCogs /> },
];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {breakpoint: 1024, settings: {slidesToShow: 4}},
        {breakpoint: 768, settings: {slidesToShow: 3}},
        {breakpoint: 480, settings: {slidesToShow: 2}},
    ],
};

const ServiceCarousel = () => {

    return (
        // Updated JSX structure in ServicesCarousel.js
        <div className="horizontal-slider" style={{ fontFamily: 'Playwrite New Zealand' }}>
            <Slider {...settings}>
                {services.map((service, index) => (
                    <div key={index} className="slide">
                        <div className="slide-icon">{service.icon}</div>
                        <div className="slide-name">{service.name}</div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default ServiceCarousel;
